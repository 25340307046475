<template>
  <v-card flat>
    <v-card-title>{{ $t("view.PageMainPage.220901.sponsors") }}</v-card-title>
    <v-card-text v-if="!sponsorApi.isLoading">
      <Sponsors :items="sponsors.Event"/>
      <!-- <div class="d-flex justify-space-around flex-wrap">
        <div v-for="(sponsor, i) in sponsors.Event" :key="i">
          <v-card outlined class="text-center my-5">
            <SponsorLogo
              :size="200"
              v-if="sponsor.Sponsor.logoUrl"
              :school="sponsor.Sponsor"
            />
            <div class="pa-2">
              <a
                v-if="sponsor.Sponsor.websiteUrl"
                class="text-decoration-none"
                :href="sponsor.Sponsor.websiteUrl"
                target="_blank"
                >{{ sponsor.Sponsor.name }}</a
              >
              <span class="black--text" v-else>{{ sponsor.Sponsor.name }}</span>
            </div>
          </v-card>
        </div>
      </div> -->
      <div class="d-flex justify-space-around flex-wrap">
        <div v-for="(sponsor, i) in sponsors.Series" :key="i">
          <v-card outlined class="text-center my-5">
            <SponsorLogo
              :size="200"
              v-if="sponsor.Sponsor.logoUrl"
              :school="sponsor.Sponsor"
            />
            <div class="pa-2">
              <a
                v-if="sponsor.Sponsor.websiteUrl"
                class="text-decoration-none"
                :href="sponsor.Sponsor.websiteUrl"
                target="_blank"
                >{{ sponsor.Sponsor.name }}</a
              >
              <span class="black--text" v-else>{{ sponsor.Sponsor.name }}</span>
            </div>
          </v-card>
        </div>
      </div>
    </v-card-text>
    <ALoader v-else :isLoading="true" />
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import Sponsors from "@/components/Sponsor/Sponsors.vue";
import SponsorLogo from "@/components/Scoreboard/SponsorLogo.vue";
export default {
  components: {
    SponsorLogo,
    Sponsors,
  },
  computed: mapState({
    //
  }),
  props: ["group"],
  data: () => ({
    //BOC:[api]
    sponsorApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    sponsors: [],
  }),
    created() {
    this.sponsorApi.method = "get";
    this.sponsorApi.callbackReset = () => {
      this.sponsorApi.isLoading = true;
      this.sponsorApi.isError = false;
    };
    this.sponsorApi.callbackError = (e) => {
      this.sponsorApi.isLoading = false;
      this.sponsorApi.isError = true;
      this.sponsorApi.error = e;
    };
    this.sponsorApi.callbackSuccess = (resp) => {
      this.sponsorApi.isLoading = false;
      this.sponsors = resp.Sponsor;
  }
  },
  mounted() {
    this.fetchSponsor()
  },
  methods: {
    fetchSponsor() {
      this.isLoading = true;
      var institutionId = 0;
      var classroomId = 0;
      if (this.group.Institution && this.group.Institution.id) {
        institutionId = parseInt(this.group.Institution.id);
      } else {
        if (this.group.Classroom && this.group.Classroom.id) {
          classroomId = parseInt(this.group.Classroom.id);
        }
      }
      this.sponsorApi.url =
        this.$api.servers.event +
        "/api/v1/" +
        this.$_getLocale() +
        "/main/event/" +
        this.$route.params.eventCode +
        "/scoreboard/sponsor?institutionId=" +
        institutionId +
        "&classroomId=" +
        classroomId;
      this.$api.fetch(this.sponsorApi);
    },
  },
};
</script>