<template>
  <div>
    <div class="d-flex pb-6 text-right">
      <v-spacer></v-spacer>
      <v-btn
        color="orange"
        v-if="auth.Session && auth"
        class="mr-2"
        dark
        @click="generateReport"
        :loading="generatingPDF"
      >
        下载完赛者PDF
      </v-btn>
      <div>
        <JsonExcel :name="lable + '.xls'" worksheet="Hamochi" :data="printData">
          <v-btn color="primary" dark>
            {{ $t("action.download_excel") }}
          </v-btn>
        </JsonExcel>
      </div>
    </div>
    <div>
      {{ $t("model.prop.total") }} {{ data.length }}
      {{ $t("model.name.students") }}
    </div>
    <v-simple-table style="border: 1px solid rgba(0, 0, 0, 0.12)">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">{{ $t("model.prop.no") }}</th>
            <th class="text-left" colspan="2">
              <span>{{ $t("model.name.student") }}</span>
            </th>
            <th class="text-left">{{ $t("model.name.classroom") }}</th>
            <!-- <th class="text-center">{{ $t("model.name.attempts") }}</th> -->
            <th class="text-right">{{ $t("model.name.submissions") }}</th>
            <th class="text-right">{{ $t("model.prop.stars") }}</th>
            <th class="text-right" v-if='eventType == "math"'>{{ $t("model.prop.score") }}</th>
          </tr>
        </thead>
        <tbody class="table">
          <tr class="text-center" v-if="data.length == 0">
            <td colspan="12" class="grey--text">
              {{ $t("api.response.no_data") }}
            </td>
          </tr>
          <tr v-for="(item, index) in data" :key="index">
            <td>{{ index + 1 }}</td>
            <td>
              <v-lazy>
                <AAvatar v-if="item.avatar" :avatar="item.avatar"></AAvatar>
                <div v-else>
                  <v-img
                    :max-height="40"
                    :max-width="40"
                    :src="require('@/assets/CS0040_default_F0.png')"
                  ></v-img>
                </div>
              </v-lazy>
            </td>
            <td class="text-uppercase">
              <router-link
                v-if="item.mochiId && item.avatar"
                class="text-decoration-none"
                :to="{
                  name: 'PageMainEventScoreboardStudent',
                  params: {
                    mochiId: item.mochiId,
                    eventCode: eventCode,
                  },
                  query: { c: $route.query.s },
                }"
                >{{ item.name }}</router-link
              >
              <span v-else>{{ item.name }}</span>
            </td>
            <td class="text-left text-uppercase">{{ item.Classroom.name }}</td>
            <!-- <td>{{ item.totalAttempt }}</td> -->
            <td class="text-right">{{ item.totalSubmission }}</td>
            <td class="text-right">
              <v-chip x-small color="yellow">{{ item.totalStar }} ★</v-chip>
            </td>
            <td class="text-right" v-if='eventType == "math"'>{{ item.totalScore }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="Finisher"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <section class="pdf-item pdf-content">
          <v-list>
            <v-list-item-group>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-black"
                    > {{$t("view.PageMainEventScoreboard.event_pdf_total_winner")}}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text>
                    {{ finishers.length }}</v-list-item-action-text
                  >
                </v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-black"
                    > {{$t("view.PageMainEventScoreboard.event_pdf_total_winner_by_school")}}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-for="year in finisherCountBySchoolYear"
                :key="year"
              >
                <v-list-item-content>
                  <v-list-item-title
                    >{{ $t("string.schoolYear_prefix") }} {{ year.schoolYear }} {{ $t("string.schoolYear_suffix") }}:</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text>
                    {{ year.count }}</v-list-item-action-text
                  >
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <div class="text-h6 pa-3"> {{$t("view.PageMainEventScoreboard.event_pdf_top5_winner")}}</div>
          <div
            v-for="(topStudents, schoolYear) in topStudentsBySchoolYear"
            :key="schoolYear"
          >
            <div v-if="schoolYear < 3">{{ $t("string.schoolYear_prefix") }} {{ schoolYear }} {{ $t("string.schoolYear_suffix") }}</div>
            <v-simple-table
              v-if="schoolYear < 3"
              style="border: 1px solid rgba(0, 0, 0, 0.12)"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">{{ $t("model.prop.no") }}</th>
                    <th class="text-left">{{ $t("model.name.student") }}</th>
                    <th class="text-left">{{ $t("model.name.classroom") }}</th>
                    <th class="text-left" v-if='eventType == "math"'>{{ $t("model.prop.score") }}</th>
                  </tr>
                </thead>
                <tbody class="table">
                  <tr v-for="(student, index) in topStudents" :key="index">
                    <td class="grey--text text-left">
                      {{ index + 1 }}
                    </td>
                    <td class="text-uppercase text-left">
                      {{ student.name }}
                    </td>
                    <td class="text-left text-uppercase">
                      {{ student.Classroom.name }}
                    </td>
                    <td v-if='eventType == "math"' class="text-left text-uppercase">
                      {{ student.totalScore }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </section>
        <div class="html2pdf__page-break" />
        <section class="pdf-item pdf-content">
          <div
            v-for="(topStudents, schoolYear) in topStudentsBySchoolYear"
            :key="schoolYear"
          >
            <div v-if="schoolYear >= 3 && schoolYear < 6">
              {{ $t("string.schoolYear_prefix") }} {{ schoolYear }} {{ $t("string.schoolYear_suffix") }}
            </div>
            <v-simple-table
              v-if="schoolYear >= 3 && schoolYear < 6"
              style="border: 1px solid rgba(0, 0, 0, 0.12)"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">{{ $t("model.prop.no") }}</th>
                    <th class="text-left">{{ $t("model.name.student") }}</th>
                    <th class="text-left">{{ $t("model.name.classroom") }}</th>
                    <th class="text-left" v-if='eventType == "math"'>{{ $t("model.prop.score") }}</th>
                  </tr>
                </thead>
                <tbody class="table">
                  <tr v-for="(student, index) in topStudents" :key="index">
                    <td class="grey--text text-left">
                      {{ index + 1 }}
                    </td>
                    <td class="text-uppercase text-left">
                      {{ student.name }}
                    </td>
                    <td class="text-left text-uppercase">
                      {{ student.Classroom.name }}
                    </td>
                    <td class="text-left text-uppercase" v-if='eventType == "math"'>
                      {{ student.totalScore }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </section>
        <div class="html2pdf__page-break" />
        <section class="pdf-item pdf-content">
          <div
            v-for="(topStudents, schoolYear) in topStudentsBySchoolYear"
            :key="schoolYear"
          >
            <div v-if="schoolYear >= 6">{{ $t("string.schoolYear_prefix") }} {{ schoolYear }} {{ $t("string.schoolYear_suffix") }}</div>
            <v-simple-table
              v-if="schoolYear >= 6"
              style="border: 1px solid rgba(0, 0, 0, 0.12)"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">{{ $t("model.prop.no") }}</th>
                    <th class="text-left">{{ $t("model.name.student") }}</th>
                    <th class="text-left">{{ $t("model.name.classroom") }}</th>
                    <th class="text-left" v-if='eventType == "math"'>{{ $t("model.prop.score") }}</th>
                  </tr>
                </thead>
                <tbody class="table">
                  <tr v-for="(student, index) in topStudents" :key="index">
                    <td class="grey--text text-left">
                      {{ index + 1 }}
                    </td>
                    <td class="text-uppercase text-left">
                      {{ student.name }}
                    </td>
                    <td class="text-left text-uppercase">
                      {{ student.Classroom.name }}
                    </td>
                    <td class="text-left text-uppercase" v-if='eventType == "math"'>
                      {{ student.totalScore }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </section>
        <div class="html2pdf__page-break" />
     
            <section v-for="i in Math.ceil(pdfData.length / 21)" :key="i" class="pdf-item pdf-content">
              <div class="text-h6" v-if="i == 1"> {{$t("view.PageMainEventScoreboard.event_pdf_winner_list")}}</div>
              <v-simple-table style="border: 1px solid rgba(0, 0, 0, 0.12)">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">{{ $t("model.prop.no") }}</th>
                      <th class="text-left">
                        <span>{{ $t("model.name.student") }}</span>
                      </th>
                      <th class="text-left">
                        {{ $t("model.name.classroom") }}
                      </th>
                      <th class="text-left">
                        {{ $t("model.prop.schoolYear") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody class="table">
                    <tr class="text-center" v-if="pdfData.length == 0">
                      <td colspan="12" class="grey--text">
                        {{ $t("api.response.no_data") }}
                      </td>
                    </tr>
                    <tr
                      v-for="(item, index) in pdfData.slice(
                        (i - 1) * 21,
                        (i - 1) * 21 + 21
                      )"
                      :key="index + i"
                    >
                      <td>{{ index + 1 + (i - 1) * 21 }}</td>
                      <td class="text-uppercase text-left">
                        {{ item.name }}
                      </td>
                      <td class="text-left text-uppercase">
                        {{ item.Classroom.name }}
                      </td>
                      <td class="text-left">
                        {{ $t("string.schoolYear_prefix") }} {{ item.Classroom.schoolYear }} {{ $t("string.schoolYear_suffix") }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </section>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import { mapState } from "vuex";
import JsonExcel from "vue-json-excel";
import VueHtml2pdf from "vue-html2pdf";
export default {
  components: {
    JsonExcel,
    VueHtml2pdf,
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: ["data", "eventCode","eventType", "lable", "finishers"],
  data: () => ({
    printData: [],
    pdfData: [],
    finisherCountBySchoolYear: [],
    topStudentsBySchoolYear: {},
    generatingPDF: false,
  }),
  created() {
    //
  },
  mounted() {
    this.pdfData = this.$_.orderBy(
      this.finishers,
      ["Classroom.name", "name"],
      ["asc", "asc"]
    );
    const groupedBySchoolYear = this.$_.groupBy(
      this.finishers,
      "Classroom.schoolYear"
    );
    this.finisherCountBySchoolYear = this.$_.map(
      groupedBySchoolYear,
      (classrooms, schoolYear) => ({
        schoolYear: parseInt(schoolYear),
        count: classrooms.length,
      })
    );
    for (const schoolYear in groupedBySchoolYear) {
      const studentsByYear = groupedBySchoolYear[schoolYear];
      const sortedStudents = this.$_.orderBy(
        studentsByYear,
        ["totalScore"],
        ["desc"]
      );
      const top5Students = sortedStudents.slice(0, 5);
      this.topStudentsBySchoolYear[schoolYear] = top5Students;
    }

    var printData = this.data.map((obj) => {
      return {
        Name: obj.name,
        Classroom: obj.Classroom.name,
        "Total Submission": obj.totalSubmission,
        "Total Star": obj.totalStar,
        "Total Score": obj.totalScore,
      };
    });
    printData = this.$_.sortBy(printData, ["Classroom", "Name"]);
    this.printData = printData.map((obj, index) => {
      return {
        No: index + 1,
        ...obj,
      };
    });
  },
  methods: {
    generateReport() {
      this.generatingPDF = true;
      this.$refs.html2Pdf.generatePdf();
      setTimeout(() => {
        this.generatingPDF = false;
      }, 1000);
    },
  },
};
</script>
<style>
.pdf-content {
  margin: 0.5cm !important; /* Set your desired margins here */
}
</style>